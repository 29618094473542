// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-event-template-index-js": () => import("../src/components/event-template/index.js" /* webpackChunkName: "component---src-components-event-template-index-js" */),
  "component---src-components-game-template-index-js": () => import("../src/components/game-template/index.js" /* webpackChunkName: "component---src-components-game-template-index-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tricks-js": () => import("../src/pages/tricks.js" /* webpackChunkName: "component---src-pages-tricks-js" */)
}

